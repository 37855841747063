import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import ProductTemplate from '../../templates/productTemplate';

const ProductList = () => {
  const { t } = useTranslation();

  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState("")
  const webVersions = ['new-calc-A', 'new-calc-B']

  useEffect(() => {
    const curVersion = localStorage.getItem("website-version")
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
    const randomVersion = webVersions[randomIndex]
    if (!curVersion || curVersion === undefined || !webVersions.includes(curVersion)) {
      localStorage.setItem("website-version", randomVersion)
      setWebsiteVersion(randomVersion)
    } else {
      setWebsiteVersion(curVersion)
    }
  }, [])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        pageURL: "/product-3",
        testovani: websiteVersion,
        event: "ga.pageview"

      })
  }, [websiteVersion])

  return (
    <>
      <li>{t("home.program2.productListItem1")}</li>
      <li>{t("home.program2.productListItem2")}</li>
      <li>{t("home.program2.productListItem3")}</li>
    </>
  )
}

const Product3 = () => {
  const { t } = useTranslation();

  return (
    <ProductTemplate
      productId="3"
      title={t("home.program2.program3title")}
      productList={ProductList}
    />
  )
}

export default Product3
